/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/jquery-validation@1.21.0/dist/jquery.validate.min.js
 * - /npm/jquery-serializejson@2.9.0/jquery.serializejson.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/magnific-popup@1.2.0/dist/jquery.magnific-popup.min.js
 * - /npm/intl-tel-input@15.1.2/build/js/intlTelInput-jquery.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
